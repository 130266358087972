import React, { useContext, createContext, useState, useEffect } from "react";
import { getUserInfo } from "services/http-requests";

export const CurrentUserContext = createContext();

const CurrentUserContextProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);

    const getCurrentUser = async () => {
        await getUserInfo()
        .then(response => setCurrentUser(response.data.object))
    }

    useEffect(() => {
        getCurrentUser()
    }, [])

    return (
        <CurrentUserContext.Provider value={{ setCurrentUser, currentUser }}>
            {children}
        </CurrentUserContext.Provider>
    );
};

export default CurrentUserContextProvider;

export const useCurrentUser = () => useContext(CurrentUserContext);
