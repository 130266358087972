import { Navigate, useNavigate } from "react-router-dom";
import { useCurrentUser } from "context/current-user-provider";
import { toast } from "react-toastify";
import useGetUserInfo from "hooks/useGetUserInfo";

// Redirect user when not logged in
const PrivateRouteAdmin = ({ children }) => {
    const token = JSON.parse(localStorage.getItem("auth-user")) || null;
    const { currentUser } = useCurrentUser();
    const { userInfo } = useGetUserInfo();

    if (!token) {
        return <Navigate to="/login" />;
    } else {
        if (currentUser === null) {
            userInfo();
        }
    }

    if (
        currentUser &&
        currentUser?.type !== "1" &&
        currentUser?.type !== "2" &&
        currentUser?.type !== "5"
    ) {
        toast.error("شما اجازه دسترسی ندارید !!!");
        return <Navigate to="/" />;
    } else {
        return children;
    }

};

export default PrivateRouteAdmin;
